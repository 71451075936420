.formImg{
	width: 75%;
	height: 75%;
	object-fit: scale-down;
}

.imgContainer{
	display: flex;
	justify-content: center;
	align-items: center;
}