body {
    font: 14px "Futura", sans-serif;
    margin: 0px;
    overflow-x: hidden;
}

.game {
    max-width: 100%; /* allow window to resize with viewport */
/*    display: flex;*/
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    width: 100%;
}

.game-info {
    margin-left: 20px;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}
