.card-group {
	display: flex;
	overflow-x: auto;
	margin-top: 20px;
	padding: 5px;
	max-width: 100%;
	width:100%;
}

.card-select-container {
	height: 350px;
	width: 225px;
	min-width: 225px;
	/*	background-color: #eee;*/
	margin-right: 5px;
	display: block;
}

.card-select-container-selected{
	box-shadow:  0px 0px 15px rgba(243, 0, 0, 1);
	border:  3px solid rgba(243, 0, 0, 1);
}