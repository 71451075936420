.card{
	height: 100%;
	width: 100%;

	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: rotateX(0deg);


	display: flex;
	justify-content: center;
	align-items: center;
}

.card-text{
	color: white;
	text-align: center;
}