.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.inputGroup {
    max-width:90%;
    margin:10px;
}