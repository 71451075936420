.flipcard-container{
	height: 350px;
	width: 225px;

	perspective: 2000px;
}

.card-two-sided{
	height: 100%;
	width: 100%;

	transform-style: preserve-3d;

	position: relative;

	border-radius: 5px;
	border:  5px solid white;
	box-shadow:  0px 0px 15px rgba(0,0,0, 0.8);

	user-select: none;
	cursor: pointer;
}

.card-side, .card-side-flipped{
	transform-style: preserve-3d;
	position: absolute;
	height: 100%;
	width: 100%;
}

.card-side-flipped{
	transform: rotateY(180deg);
}

/*TRANSITIONS*/
.flip-front{
	transform: rotateY(0deg);
}
.flip-back{
	transform: rotateY(180deg);
}
.flip-front-to-back{
	transform: rotateY(180deg);
	transition: transform 300ms;	
}
.flip-back-to-front{
	transform: rotateY(0deg);
	transition: transform 300ms;	
}